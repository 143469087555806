<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary" v-if="permFilterVendor()">
   
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <label>Bulk Upload</label>
      <h4 class="text-lg font-semibold">Bulk Upload</h4>
    </div>
    <div class="w-full px-4 py-4 mt-4 flex justify-between rounded-lg items-center mb-5 bg-white">
           <div class="w-1/2">
            <label>Vendor</label>
            <multiselect v-model="vendor" label="name" track-by="name" placeholder="Enter Vendor"
            open-direction="bottom" :options="vendorSearchOptions" :searchable="true"
            :loading="isVendorSearch" :close-on-select="true" :multiple="false" @search-change="vendorFind">
        </multiselect>
          </div>
       
        <div class="w-1/2 flex mt-4 justify-end">
          <button @click="ImportPopupBulkCSV" class="bg-white flex items-center py-2 px-3 border rounded-lg">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm1.018 8.828a2.34 2.34 0 0 0-2.373 2.13v.008a2.32 2.32 0 0 0 2.06 2.497l.535.059a.993.993 0 0 0 .136.006.272.272 0 0 1 .263.367l-.008.02a.377.377 0 0 1-.018.044.49.49 0 0 1-.078.02 1.689 1.689 0 0 1-.297.021h-1.13a1 1 0 1 0 0 2h1.13c.417 0 .892-.05 1.324-.279.47-.248.78-.648.953-1.134a2.272 2.272 0 0 0-2.115-3.06l-.478-.052a.32.32 0 0 1-.285-.341.34.34 0 0 1 .344-.306l.94.02a1 1 0 1 0 .043-2l-.943-.02h-.003Zm7.933 1.482a1 1 0 1 0-1.902-.62l-.57 1.747-.522-1.726a1 1 0 0 0-1.914.578l1.443 4.773a1 1 0 0 0 1.908.021l1.557-4.773Zm-13.762.88a.647.647 0 0 1 .458-.19h1.018a1 1 0 1 0 0-2H6.647A2.647 2.647 0 0 0 4 13.647v1.706A2.647 2.647 0 0 0 6.647 18h1.018a1 1 0 1 0 0-2H6.647A.647.647 0 0 1 6 15.353v-1.706c0-.172.068-.336.19-.457Z" clip-rule="evenodd"/>
            </svg>

            Import CSV
          </button>
        </div>
    </div>
    <div class="bg-white block w-full px-3 py-3 rounded-lg">
      <div class="flex-col w-full bg-white" v-if="isUpload">
        <div class="p-2">
        <div class="flex justify-between items-center">
              <div class="h-2 bg-gray-200 rounded-full w-full">
            <div class="bg-blue-500 h-2 rounded-full" :style="{ width: progressBar + '%' }"></div>
          </div>
        </div>
      </div>
      
      </div>
      <div class=flex-col>
        <li v-for="(item) in statusUpload" :key="item.item_name">
                  <span v-if="item.error">
                    {{ item.item_name }} - {{ item.message }}
                  </span>
                  <span v-else-if="item.is_new">
                    <router-link target="_blank" :to="{ name: DetailItems.name, params: { id: item.data.id } }">
                      {{ item.item_name }}
                    </router-link>
                    - success uploaded
                  </span>
                  <span v-else>
                    <router-link target="_blank" :to="{ name: DetailItems.name, params: { id: item.data.id } }">
                      {{ item.item_name }}
                    </router-link>
                    - item already uploaded
                  </span>
                  <span v-if="item.data">
                    <span v-if="item.data.single">
                      with single offer
                      <router-link target="_blank"
                        :to="{ name: SingleEditPath.name, params: { id: item.data.single[0].id } }">
                        {{ item.data.single[0].name }}
                      </router-link>
                    </span>
                  </span>
                </li>
      </div>
    </div>
   
    <form class="bg-white rounded-lg pb-4">
      <div class="w-full overflow-y-auto">
        <button type="button" class="bg-blue-500 px-2 py-2 text-white rounded-lg mb-4 ml-4" @click="onBulkCreateAuthor" v-if="isBulkAuthorShow()">Create all author</button>
          <t-table :headers="fields" :data="bulkData" ref="tableList" class="shadow-none w-full  max-w-full">
      <template v-slot:thead="props">
        <thead>
          <tr>
            <th :class="props.thClass" class="text-center">
              Act
            </th>
            <th :class="props.thClass + ' sticky left-0 z-10'" class="text-center">
              No
            </th>
            <th :class="props.thClass + ' sticky left-8 z-10'" style="min-width: 200px;">
          Name (Item’s Title)
        </th>
            <th :class="props.thClass">
              Author
            </th>
            <th :class="props.thClass">
              language
            </th>
            <th :class="props.thClass">
              Country
            </th>
            <th :class="props.thClass">
              category
            </th>
            <th :class="props.thClass">
              Parental Control
            </th>
            <th :class="props.thClass">
              Content type
            </th>
            <th :class="props.thClass">
              Item Type
            </th>
            <th :class="props.thClass">
              Release date
            </th>
            <th :class="props.thClass">
              Release Schedule
            </th>
            <th :class="props.thClass">
              Description
            </th>
            <th :class="props.thClass">
              Isbn
            </th>
          
            <th :class="props.thClass">
              Ios Tier
            </th>
            <th :class="props.thClass">
              Android Tier
            </th>
            <th :class="props.thClass">
              Printed Currency
            </th>
            <th :class="props.thClass">
              Printed Price
            </th>
            
            <th :class="props.thClass">
              Category Ax
            </th>
            <th :class="props.thClass">
              Offers Premium
            </th>
           </tr>
        </thead>
      </template>
          <template v-if="!bulkData.length" v-slot:tbody="props">
        <tbody :class="props.tbodyClass">
          <td></td>
          <td></td>
          <td  colspan="20" :class="props.tdClass + ' sticky left-8 bg-white z-10 '">
            <button  type="button" class="border py-2.5 block px-3 rounded-lg my-0 w-48" @click="handleAdd()">Add New</button>
          </td>
          <tr :class="[props.trClass, 'text-center']">
            <td :class="props.tdClass" colspan="20">
              <div class="flex-col items-center justify-center flex-nowrap flex h-96">
                <svg class="w-24 h-24 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 3v4a1 1 0 0 1-1 1H5m4 8h6m-6-4h6m4-8v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z"/>
                </svg>

                <h4 class="text-base text-gray-600 mb-0">No Data</h4>
                <p class="text-sm text-gray-500">There are no data to display</p>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template slot="row" slot-scope="props">
        <tr v-if="props.rowIndex=== 0">
          <td></td>
          <td></td>
          <td :class="props.tdClass + ' sticky left-8 bg-white z-10 '">
            <button  type="button" class="border py-2.5 block px-3 rounded-lg my-0 w-full" @click="handleAdd()">Add New</button>
          </td>
          <td class="bg-white" colspan="20"></td>
        </tr>
        <tr 
          class="border-b dark:bg-gray-800 dark:border-gray-700 bg-54 px-3">
          <td  :class="props.tdClass" class="w-3 text-center">
            <div class="mt-3">
              <i class="fa fa-trash fa-2 text text-gray-500" style="cursor: pointer; font-size: 24px;"
              @click="handleDelete(props)"></i>
            </div>
          </td>
          <td  :class="props.tdClass + ' sticky left-0  bg-white z-10'" class="uppercase text-center">
            <div class="mt-3">
              {{props.rowIndex + 1}}
            </div>
          </td>
          <td :class="props.tdClass + ' sticky left-8 z-10 bg-white'" style="min-width: 200px;">
            <div class="mt-3">
              <input v-model="bulkData[props.rowIndex].name" class="border p-2 rounded-lg" />
            </div>
        </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3">
              <multiselect  class="w-60" v-model="bulkData[props.rowIndex].author" label="name" track-by="name"
                            placeholder="Enter Author" open-direction="bottom" :options="authorSearchOptions"
                            :loading="isAuthorSearch" :searchable="true" :close-on-select="true" :multiple="true"
                            @search-change="authorFind">
                          </multiselect>
                          <div v-for="author in authorNotFound[props.rowIndex]" :key="author">
                            <div cols="12" class="relative top-2">
                              {{ author }}
                              <button  type="button" class="bg-blue-500 rounded-lg p-1 px-2 text-white ml-2 " @click="onCreateAuthor(author)">create</button>
                            </div>
                          </div>
            </div>
          </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3">
              <multiselect  class="w-60" placeholder="Enter languages" open-direction="bottom"
                            v-model="bulkData[props.rowIndex].languages" label="name" track-by="name"
                            :custom-label="nameWithISO" :options="languagesSearchOptions" :searchable="true"
                            :close-on-select="true" :multiple="true" >
                          </multiselect>
            </div>
          
          </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3">
            <multiselect class="w-60" placeholder="Enter Countries" open-direction="bottom"
                            v-model="bulkData[props.rowIndex].countries" label="name" track-by="name"
                            :custom-label="nameWithISO" :options="countriesSearchOptions" :searchable="true"
                            :close-on-select="true" :multiple="true">
                          </multiselect>
            </div>
          </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3">
              <multiselect  class="w-60" v-model="bulkData[props.rowIndex].categories" label="name" track-by="name"
                            placeholder="Enter Categories" open-direction="bottom" :options="categoriesSearchOptions"
                            :loading="isCategoriesSearch" :searchable="true" :close-on-select="true" :multiple="true"
                            @search-change="categoriesFind($event, bulkData[props.rowIndex])">
                          </multiselect>
            </div>
          </td>
          <td :class="props.tdClass" class="uppercase w-20" style="min-width: 200px;">
            <div class="mt-3">
            <t-select-table v-model="bulkData[props.rowIndex].parental_control" :options="parentialsControlOption">
              </t-select-table>
            </div>
          </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3">
              <t-select-table class="w-24" v-model="bulkData[props.rowIndex].content_type" :options="contentTypeOptions">
              </t-select-table>
            </div>
          </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3">
              <t-select-table class="w-24" v-model="bulkData[props.rowIndex].item_type" :options="itemTypeOptions">
              </t-select-table>
            </div>
          </td>
          <td :class="props.tdClass" class="uppercase">  
              <DatetimeCustom :timeDate="false" :label="''"  v-model="bulkData[props.rowIndex].release_date" />
          </td>
          <td :class="props.tdClass" class="uppercase">  
              <DatetimeCustom :timeDate="false" :label="''" v-model="bulkData[props.rowIndex].release_schedule" />
          </td>
          <td :class="props.tdClass" class="uppercase">  
            <div class=" w-96 block h-auto mt-4">
              <textarea class="w-full h-11 rounded-lg border mt-0 text-gray-700 " v-model="bulkData[props.rowIndex].description" />
            </div>
          </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3 w-60">
              <t-input type="number" class="w-20"  v-model="bulkData[props.rowIndex].isbn" />
            </div>
          </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3 w-64 relative">
              <multiselect v-model="bulkData[props.rowIndex].ios_tier" label="tier_code" track-by="tier_code"
                            placeholder="Ios Tier" open-direction="bottom" :options="iosTierSearchOptions"
                            :searchable="true" :loading="isIosTierSearch" :close-on-select="true" :multiple="false"
                            @search-change="iosTierFind">
                          </multiselect>
                          <em class="absolute top-11">
                            IDR : {{ bulkData[props.rowIndex].ios_tier ? bulkData[props.rowIndex].ios_tier.price_idr : 0 | formatPriceId }}
                          </em>
            </div>
          </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3 w-63 relative">
              <multiselect v-model="bulkData[props.rowIndex].android_tier" label="tier_code" track-by="tier_code"
                            placeholder="Android Tier" open-direction="bottom" :options="androidTierSearchOptions"
                            :searchable="true" :loading="isAndroidTierSearch" :close-on-select="true" :multiple="false"
                            @search-change="androidTierFind" style="min-width: 250px;">
                          </multiselect>
                           <em class="absolute top-11">
                            IDR : {{ bulkData[props.rowIndex].android_tier ? bulkData[props.rowIndex].android_tier.price_idr : 0 | formatPriceId }}
                          </em>
            </div>     
          </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3 w-63 relative">
              <t-select v-model="bulkData[props.rowIndex].printed_currency" :options="currencyOptions"
                            style="width:100px">
                          ></t-select>
            </div>     
          </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3 w-63 relative">
              <t-input v-model="bulkData[props.rowIndex].printed_price" :options="currencyOptions"
                            style="width:100px">
                          ></t-input>
            </div>     
          </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3 w-63 relative">
              <multiselect v-model="bulkData[props.rowIndex].category_ax" label="name" track-by="name"
                            placeholder="Category Ax" open-direction="bottom" :options="axSearchOptions"
                            :searchable="true" :loading="isAxSearch" :close-on-select="true" :multiple="false"
                            @search-change="axFind" style="min-width: 200px;">
                          </multiselect>
            </div>     
          </td>
          <td :class="props.tdClass" class="uppercase">
            <div class="mt-3 w-63 relative">
              <multiselect v-model="bulkData[props.rowIndex].offers_premium" label="name" track-by="name"
                            placeholder="Offers Premium" open-direction="bottom" :options="offersSearchOptions"
                            :searchable="true" :loading="isOffersSearch" :close-on-select="true" :multiple="true"
                            style="min-width: 200px;">
                          </multiselect>
            </div>     
          </td>
      
        </tr>
      </template>
    </t-table>

        </div>
    <t-modal-file ref="importCSVPopup">
      <div class="mx-auto p-2 bg-white rounded-lg">
        <h2 class="text-xl font-bold mb-2">File Upload</h2>

        <!-- File upload box -->
        <div
          class="flex flex-col items-center justify-center  bg-gray-50 rounded-lg p-6 hover:border-blue-500 cursor-pointer"
          @click="triggerFileInput"
          @drop.prevent="handleFileDrop"
          @dragover.prevent
        >
        <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v9m-5 0H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2M8 9l4-5 4 5m1 8h.01"/>
        </svg>
          <p class="mt-2 text-gray-500 mb-1 font-light">
            <span class="text-gray-600 font-semibold cursor-pointer">Click to upload</span> or drag and drop
          </p>
          <p class="text-gray-500 font-semibold ">Max. 10,000 SKU</p>

          <button  type="button" class="mt-0 bg-blue-500 flex text-white py-2.5 px-4 rounded-lg hover:bg-blue-600">
            <svg class="w-5 h-5 text-white mr-2 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
</svg>

            Browse File
          </button>

          <input ref="fileInput" type="file" accept=".csv" class="hidden" @change="handleFileChange" />
        </div>

        <!-- Download CSV template -->
        <p class="mt-4 text-sm text-center text-gray-500">
          To download CSV template, <a :href="URL_STATIC.TEMPLATE_BULK_CREATE" class="text-blue-500 font-semibold underline">Click Here</a>
        </p>
      </div>
    </t-modal-file>

    <t-modal-file-progress-bar ref="progressPopup" class="w-1/2">
      <div class="w-full mx-auto p-0 bg-white rounded-lg">
        <!-- Progress Bar -->
        <div class="mt-1 w-full">
          <h2 class="text-xl font-bold mb-0">Upload Progress</h2>
          <div class="flex justify-start items-center ">
            <div class="pt-3">
              <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm1.018 8.828a2.34 2.34 0 0 0-2.373 2.13v.008a2.32 2.32 0 0 0 2.06 2.497l.535.059a.993.993 0 0 0 .136.006.272.272 0 0 1 .263.367l-.008.02a.377.377 0 0 1-.018.044.49.49 0 0 1-.078.02 1.689 1.689 0 0 1-.297.021h-1.13a1 1 0 1 0 0 2h1.13c.417 0 .892-.05 1.324-.279.47-.248.78-.648.953-1.134a2.272 2.272 0 0 0-2.115-3.06l-.478-.052a.32.32 0 0 1-.285-.341.34.34 0 0 1 .344-.306l.94.02a1 1 0 1 0 .043-2l-.943-.02h-.003Zm7.933 1.482a1 1 0 1 0-1.902-.62l-.57 1.747-.522-1.726a1 1 0 0 0-1.914.578l1.443 4.773a1 1 0 0 0 1.908.021l1.557-4.773Zm-13.762.88a.647.647 0 0 1 .458-.19h1.018a1 1 0 1 0 0-2H6.647A2.647 2.647 0 0 0 4 13.647v1.706A2.647 2.647 0 0 0 6.647 18h1.018a1 1 0 1 0 0-2H6.647A.647.647 0 0 1 6 15.353v-1.706c0-.172.068-.336.19-.457Z" clip-rule="evenodd"/>
            </svg>

            </div>
            <div class="ml-2 pt-3">
              <p class="text-left mb-0 text-sm text-gray-700 font-semibold"> {{ this.fileName }}</p>  
              <p class="text-left text-sm text-gray-500 mb-0">{{readableFileSize(this.fileSize)}}</p>
            </div>
         </div>
            <div class="flex justify-between items-center">
              <div class="h-2 bg-gray-200 rounded-full w-11/12">
            <div class="bg-blue-500 h-2 rounded-full" :style="{ width: progress + '%' }"></div>
          </div>
          <div class="text-left text-sm text-gray-500 "> {{ progress }}% </div>
        </div>
        </div>
      </div>
    </t-modal-file-progress-bar>
    <div class="px-3 py-4" v-if="bulkData.length">
      <button type="submit" class="py-2.5 px-4 mr-3 bg-blue-500 text-white rounded-lg border" @click="onUpload">Submit</button>
      <button type="click" class="py-2.5 px-4 bg-gray-300 rounded-lg border" @click="cancel()">Cancel</button>
    </div>
    </form>
   
  </b-overlay>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import XLSX from 'xlsx';
import constant from '../../store/constant';
import { DetailItems } from '../../router/content';
import { SingleEditPath } from '../../router/marketing';
import DatetimeCustom from '../../components/form/Datetime.vue'
delete constant.ITEM_TYPES.AUDIOBOOK;
delete constant.ITEM_TYPES.NEWSPAPER;
delete constant.CONTENT_TYPE.AUDIO;
const { ROLES } = constant;
export default {
  components: {
    DatetimeCustom
  },
  data() {
    return {
      file_csv: null,
      fileName: '',
      fileSize:null,
      URL_STATIC: constant.URL_STATIC,
      vendor: null,
      isVendorSearch: false,
      authorNotFound: [],
      csvDataRaw: [],
      progress: 0,
      uploading: false,
      bulkData: [],
      intervalId: null,
      itemTypeOptions: Object.values(constant.ITEM_TYPES).map(val => val.toUpperCase()),
      // itemTypeOptions: Object.values(constant.ITEM_TYPES).slice(1).map(val => val.toUpperCase()),
      contentTypeOptions: Object.values(constant.CONTENT_TYPE).map(val => val.toUpperCase()),
      currencyOptions: Object.values(constant.PRINTED_PRICE_ITEM).map(val => val.toUpperCase()),
      fields: [
        { key: "action" },
        {
          key: "name",
          stickyColumn: true,
          label:'Name (Item’s Title)'
        },
        { key: "author" },
        { key: "languages" },
        { key: "countries" },
        { key: "categories" },
        { key: "content_type" },
        { key: "parental_control" },
        { key: "item_type" },
        { key: "release_date" },
        { key: "release_schedule" },
        { key: "description" },
        { key: "isbn" },
        { key: "ios_tier" },
        { key: "android_tier" },
        { key: "printed_currency" },
        { key: "printed_price" },
        { key: "category_ax" },
        { key: "offers_premium" },
      ],
      isValidate: false,
      isUpload: false,
      progressBar: 0,
      maxProgressBar: 100,
      authorSearchOptions: [],
      isAuthorSearch: false,
      languagesSearchOptions: [],
      countriesSearchOptions: [],
      categoriesSearchOptions: [],
      isCategoriesSearch: false,
      axSearchOptions: [],
      isAxSearch: false,
      statusUpload: [],
      debounce: null,
      offersSearchOptions: [],
      isOffersSearch: false,
      iosTierSearchOptions: [],
      isIosTierSearch: false,
      androidTierSearchOptions: [],
      isAndroidTierSearch: false,
      DetailItems,
      SingleEditPath,
    };
  },
  created() {
    this.setCountriesOptions();
    this.languagesSearchOptions = Object.entries(constant.LANGUAGE_CHOICES).map((item) => {
      return {
        iso: item[0].toUpperCase(),
        name: item[1]
      }
    });
    this.parentialsControlOption = Object.entries(constant.PARENTAL_LEVEL_TYPE).map((item) => { 
        return {
          text: item[0],
          value: item[1],
        }}),
  
    this.searchPremiumOffers({ name: '' }).then((response) => {
      this.offersSearchOptions = response.data.data.rows;
    });
    this.fetchVendors({ name:'', status: true });
    this.fetchOwnVendors();
  },
  watch: {
    async file_csv() {
      if (!this.file_csv) {
        this.reset();
        return;
      }
      this.csvToTable();
    },
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.isUpload = false;
      this.messageAlert('success', this.successMessage);
    },
    ownVendor: function(data) {
      this.vendor = data;
    }
  },
  computed: {
    ...mapGetters("auth", { auth: "auth" }),
    ...mapState({
      isError: (state) => state.items.isError,
      isLoading: (state) => state.items.isLoading,
      errorMessage: (state) => state.items.errorMessage,
      successMessage: (state) => state.items.successMessage,
      vendorSearchOptions: (state) => state.vendors.items,
      ownVendor: (state) => state.vendors.item,
    }),
  },
  methods: {
    ...mapActions('vendors', ['fetchVendors', 'fetchOwnVendors']),
    ...mapActions('items', ['bulkCreateUpload', 'getCountries', 'searchCategoriesV2']),
    ...mapActions('ax', ['searchCategoryAx']),
    ...mapActions('author', ['searchAuthor', 'postAuthor']),
    ...mapActions('brands', ['searchBrands']),
    ...mapActions('offerspremium', {
      searchPremiumOffers: 'searchOffers',
    }),
    ...mapActions('tiercodeAndroid', {
      searchAndroidTier: 'searchAndroidTierSpecific',
    }),
    ...mapActions('tiercodeIOS', {
      searchIosTier: "searchIosTierSpecific",
    }),
    async onUpload(event) {
      event.preventDefault();
      this.statusUpload = [];
      if (!this.vendor?.id) {
        this.messageAlert('error', 'vendor not selected!', 5000);
        return;
      }
      if (!this.bulkData.length) {
        this.messageAlert('error', 'Empty Data!', 5000);
        return;
      }

      // validate null object
      for (const [idx, data] of this.bulkData.entries()) {
        const formatData = this.formatData(data);
        delete formatData.offers_premium;
        delete formatData.price_usd;
        const checkData = Object.values(formatData);
        if (checkData.includes(null) || checkData.includes(undefined) || checkData.includes('')) {
          this.messageAlert('error', `please fill in completely in row ${idx + 1}`, 5000);
          return;
        }
      }

      // const releaseSchedule = new Date();
      // releaseSchedule.setTime(releaseSchedule.getTime() + (60*60*1000));
      // prepare data before bulk
      const bulkData = this.bulkData.map(val => {
        const formatData = this.formatData(val);
        formatData.row_id = val.row_id;
        formatData.vendor_id = this.vendor.id;
        formatData.item_type = val.item_type.toLowerCase();
        formatData.content_type = val.content_type.toLowerCase();
        formatData.author_id = val.author?.map(item => item.id);
        formatData.categories_id = val.categories.map(item => item.id);
        formatData.category_ax_id = val.category_ax.id,
        formatData.parental_control = val.parental_control;
        formatData.release_schedule = val.release_schedule;
        formatData.issue_number = val.author?.map(item => item.name).join();
        formatData.countries = val.countries.map(item => item.iso.toLowerCase());
        formatData.languages = val.languages.map(item => item.iso.toLowerCase());
        formatData.offers_premium = (val.offers_premium || []).map(val => val.id);
        formatData.gtin13 = val.isbn;
        return formatData;
      })

      this.$store.commit(`items/setLoading`, true);
      this.isUpload = true;

      // set progress bar
      this.progressBar = 0;
      const progressPerData = this.maxProgressBar / this.bulkData.length;

      for (const payload of bulkData) {
        const result = await this.bulkCreateUpload(payload);
        result.item_name = payload.name;
        this.statusUpload.push(result);
        if (!result.error) {
          this.bulkData = this.bulkData.filter(val => val.row_id != payload.row_id);
        }
        this.progressBar += progressPerData;
      }
      this.isUpload = false;
      this.$store.commit(`items/setLoading`, false);

    },
    reset() {
      this.file_csv = null;
      this.statusUpload = [];
      this.csvDataRaw = [];
      this.bulkData = [];
    },

    boolCheck() {
      return {
        'true': true,
        'yes': true,
        '1': true,
        'false': false,
        'no': false,
        '0': false,
      };
    },
    ImportPopupBulkCSV() {
      this.$refs.importCSVPopup.show();
    },
    cancel() {
      this.$refs.importCSVPopup.hide();
      this.selectedItem = []
    }, 
    saveCSV() {

    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.fileName = file.name
      this.fileSize = file.size
      if (file) {
        if (!file.name.endsWith('.csv')) {
          alert('Invalid file format. Only CSV files are allowed.');
          return;
        }
        this.uploading = true;
        this.file_csv = file; 
        this.$refs.importCSVPopup.hide(); 
        this.$refs.progressPopup.show();
      }
    },
    loadBulkData() {
      const totalChunks = this.csvDataRaw.length;    
      let loadedChunks = 0;
      this.progress = 0;

      this.intervalId = setInterval(() => {
        if (loadedChunks < totalChunks) {
          loadedChunks += 1; 
          this.progress = Math.floor((loadedChunks / totalChunks) * 100);
        } else {
          clearInterval(this.intervalId);
          this.uploading = false;
          setTimeout(() => {
            this.$refs.progressPopup.hide();
            this.isLoading = false
          }, 500); 
        }
      }, 500);
    },
    handleFileDrop(event) {
      const file = event.dataTransfer.files[0];
      if (file) {
        this.handleFileChange({ target: { files: [file] } });
      }
    },
   
    csvToTable() {
      const reader = new FileReader()
      reader.readAsBinaryString(this.file_csv)
      reader.onload = () => {
        const workbook = XLSX.read(reader.result, {
          type: 'binary',
          cellDates: true,
          raw: true,
          dateNF: 'yyyy-mm-dd hh:mm:ss',
        });
        let csvData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
        this.csvDataRaw = csvData;
        this.loadBulkData()
        this.validateDataCsv();
      }
    },

    async validateDataCsv() {
      this.isValidate = true;
      this.authorNotFound = [];
      this.bulkData = this.csvDataRaw.map(
        (item, idx) => {
          const formatData = this.formatData(item);
          const parentalMatch = this.parentialsControlOption.find(option => option.text === item.parental_control);
          formatData.parental_control = parentalMatch ? parentalMatch.value : null;
          formatData.content_type = item.content_type.toUpperCase();
          formatData.item_type = item.item_type.toUpperCase();
          formatData.row_id = idx;
          return formatData;
        });
      for (const val of this.bulkData) {
        const lang = val.languages?.toLowerCase().trim().split('#');
        if (lang.length) {
          val.languages = this.languagesSearchOptions.filter(lg => lang.includes(lg.name.toLowerCase()))
        }
        const countries = val.countries?.toLowerCase().trim().split('#');
        if (countries.length) {
          val.countries = this.countriesSearchOptions.filter(cn => countries.includes(cn.name.toLowerCase()))
        }

        const authors = val.author?.trim().split('#');
        val.author = [];
        const tempAuthorNotFound = [];
        for (let author of (authors || [])) {
          const query = author.toLowerCase();
          const check = this.authorSearchOptions.find(as => as.name.toLowerCase() == query);
          if (check?.name) {
            val.author.push(check);
            continue;
          }

          // query = encodeURIComponent(query);
          const response = await this.searchAuthor({ name: query });
          // query = decodeURIComponent(query);
          const rows = response.data.data?.rows;
          const findAuthor = rows.find(au => au.name.toLowerCase() == query.toLowerCase());
          if (findAuthor?.name) {
            this.authorSearchOptions.push(findAuthor);
            val.author.push(findAuthor);
          } else {
            tempAuthorNotFound.push(author);
          }
        }
        this.authorNotFound.push(tempAuthorNotFound);

        const categories = val.categories?.toLowerCase().trim().split('#');
        val.categories = [];
        for (let query of (categories || [])) {
          query = query.toLowerCase();
          const check = this.categoriesSearchOptions.find(as => as.name.toLowerCase() == query);
          if (check?.name) {
            val.categories.push(check);
            continue;
          }

          const response = await this.searchCategoriesV2({
            name: query,
            is_active: 1,
            item_type: val.item_type?.toLowerCase(),
          });
          const rows = response.data.data?.rows;
          const findCategory = rows.find(ct => ct.name.toLowerCase() == query)
          if (findCategory?.name) {
            this.categoriesSearchOptions.push(findCategory);
            val.categories.push(findCategory);
          }
        }

        const premiums = val.offers_premium?.toLowerCase().trim().split('#');
        val.offers_premium = [];
        for (let query of (premiums || [])) {
          query = query.toLowerCase();
          const check = this.offersSearchOptions.find(op => op.name.toLowerCase() == query);
          if (check) {
            val.offers_premium.push(check);
          }
        }

        const checkAx = this.axSearchOptions.find(ct => ct.name?.trim().toLowerCase() == val.category_ax.trim().toLowerCase());
        if (checkAx) {
          val.category_ax = checkAx;
        } else {
          let categoryAx = await this.searchCategoryAx({ q: val.category_ax });
          categoryAx = categoryAx.data.data?.rows;
          val.category_ax = categoryAx.find(ct => ct.name.toLowerCase() == val.category_ax.toLowerCase());
          this.axSearchOptions.push(...categoryAx);
        }

        let iosTier = await this.searchIosTier({ q: `.c.usd.${val.price_usd}` });
        let androidTier = await this.searchAndroidTier({ q: `.c.usd.${val.price_usd}` });
        val.ios_tier = iosTier.data?.data;
        val.android_tier = androidTier.data?.data;
      }
      this.isValidate = false;
    },

    vendorFind(query) {
      if (!query) return;

      this.isVendorSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.fetchVendors({ name: query, status: true }).then(() => {
          this.isVendorSearch = false;
        }).catch(() => {
          this.isVendorSearch = false;
        });
      }, 600);
    },

    authorFind(query) {
      if (!query) return;

      this.isAuthorSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          limit: 10,
        };
        this.searchAuthor(payload)
          .then((response) => {
            this.authorSearchOptions = response.data.data.rows;
            this.isAuthorSearch = false;
          })
          .catch(() => {
            this.isAuthorSearch = false;
          });
      }, 600);
    },

    axFind(query) {
      if (!query) return;

      this.isAxSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchCategoryAx(payload).then((response) => {
          this.axSearchOptions = response.data.data.rows;
          this.isAxSearch = false;
        }).catch(() => {
          this.isAxSearch = false;
        });
      }, 600);
    },

    async onCreateAuthor(authorName) {
      await this.postAuthor({
        name: authorName,
        isActive: true,
      });
      this.validateDataCsv();
    },

    cleanAuthorNotFound() {
      let authors = [];
      this.authorNotFound.forEach(val => {
        authors.push(...val);
      });
      authors = [...new Set(authors)];

      return authors;
    },

    isBulkAuthorShow() {
      const authors = this.cleanAuthorNotFound();
      return !!authors.length;
    },

    async onBulkCreateAuthor() {
      this.isValidate = true;
      const authors = this.cleanAuthorNotFound();

      for (const author of authors) {
        await this.postAuthor({
          name: author,
          isActive: true,
        });
      }

      this.validateDataCsv();
    },

    iosTierFind(query) {
      if (!query) return;

      this.isIosTierSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchIosTier({ q: query }).then((response) => {
          this.iosTierSearchOptions = [response.data.data];
          this.isIosTierSearch = false;
        }).catch(() => {
          this.isIosTierSearch = false;
        });
      }, 600);
    },

    androidTierFind(query) {
      if (!query) return;

      this.isAndroidTierSearch = true;
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.searchAndroidTier({ q: query }).then((response) => {
          this.androidTierSearchOptions = [response.data.data];
          this.isAndroidTierSearch = false;
        }).catch(() => {
          this.isAndroidTierSearch = false;
        });
      }, 600);
    },

    setCountriesOptions() {
      this.isCountriesSearch = true;
      this.getCountries()
        .then((response) => {
          this.countriesSearchOptions = response.data.data.rows;
          this.isCountriesSearch = false;
        })
        .catch(() => {
          this.isCountriesSearch = false;
        });
    },

    categoriesFind(query, data) {
      if (!query) return;

      this.isCategoriesSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchCategoriesV2({
          is_active: 1,
          name: query,
          item_type: data.item_type?.toLowerCase(),
        })
          .then((response) => {
            this.categoriesSearchOptions = response.data.data.rows;
            this.isCategoriesSearch = false;
          })
          .catch(() => {
            this.isCategoriesSearch = false;
          });
      }, 600);
    },

    formatData(data = {}, defaultValue = null) {
      const format = {};
      this.fields.forEach(val => format[val.key] = data[val.key] ?? defaultValue);
      format.price_usd = data.price_usd ?? defaultValue;
      delete format.action;
      return format;
    },

    handleAdd() {
      const newRow = this.formatData();
      newRow.id = this.bulkData.length;
      this.bulkData.push(newRow);
    },

    handleDelete(data) {
      this.bulkData.splice(data.rowIndex, 1);
      this.authorNotFound.splice(data.rowIndex, 1);
    },

    nameWithISO({ name, iso }) {
      return `${iso} - ${name}`
    },
    readableFileSize(attachmentSize) {
      const DEFAULT_SIZE = 0;
      const fileSize = attachmentSize ?? DEFAULT_SIZE;

      if (!fileSize) {
        return `${DEFAULT_SIZE} kb`;
      }

      const sizeInKb = fileSize / 1024;

      if (sizeInKb > 1024) {
        return `${(sizeInKb / 1024).toFixed(2)} mb`;
      } else {
        return `${sizeInKb.toFixed(2)} kb`;
      }
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    permFilterVendor() {
      switch (this.auth.role_id) {
        case ROLES.SUPER_ADMIN:
          return true
        case ROLES.INTERNAL_ADMIN:
          return true
        case ROLES.INTERNAL_DATA_ENTRY:
          return true
        default:
          return false;
      }
    },
  },
  filters: {
    convertSize(value) {
      const size = parseFloat(value);
      return (size / 1000 ** 2).toFixed(3)
    },
    formatPriceId(value = 0) {
      return parseFloat(value).toLocaleString('id');
    }
  }
};
</script>

<style scoped>
 
</style>